<template>
  <v-container>
    <v-alert type="info">{{ $t("agree_use_terms_alert") }}</v-alert>
    <v-card class="pa-8 use-terms-card">
      <div class="use-terms-container">
        <div class="header">
          <v-icon x-large color="primary">mdi-file-sign</v-icon>
          <span class="title">{{ $t("terms_use") }}</span>
        </div>

        <div>
          <v-dialog persistent v-model="terms" v-if="terms">
            <TermosPDFModal
              type="terms"
              :Header="$t('terms_use')"
              :Html="contractHtmlUseTermsContent"
              :Subtitle="contractHtmlUseTermsAgent"
              @agree="agreeTerms"
              @decline="declineTerms"
            />
          </v-dialog>
          <v-checkbox
            style="background-color: var(--white)"
            class="mt-12 pa-1 mb-3 alpha-checkbox"
            hide-details
            readonly
            v-model="useTerms"
            @click="openTerms"
          >
            <template v-slot:label>
              <span style="color: var(--dark); font-size: 16px"
                >{{ $t("read_agreed")
                }}<a class="ml-1" @click="openTerms"
                  ><strong
                    style="color: var(--dark); text-decoration: underline"
                    >{{ $t("terms_use") }}</strong
                  ></a
                ></span
              >
            </template></v-checkbox
          >
        </div>

        <v-btn
          @click="handleAgreeTerms"
          :loading="loading"
          :disabled="!useTerms"
          color="primary"
          class="btn-agreement"
          >{{ $t("agree_continue") }}</v-btn
        >
      </div>
    </v-card>
  </v-container>
</template>

<script>
import TermosPDFModal from "@/components/dxa/pdfModal/PdfModal";
import UserService from "@/services/UserService";
import { contractTypesEnum } from "@/shared/enums/ContractTypesEnum.js";
import ApiService from "@/services/ApiService";

export default {
  components: {
    TermosPDFModal,
  },
  name: "UseTerms",
  data() {
    return {
      userService: new UserService(),
      apiService: new ApiService(),
      loading: false,
      useTerms: false,
      termsOpened: false,
      terms: false,
      contractTypesEnum,
      contractHtmlUseTermsContent: "",
      contractHtmlUseTermsAgent: "",
      user: null,
    };
  },
  async created() {
    this.user = JSON.parse(localStorage.getItem("user"));

    await this.contractHtmlUseTerms();
  },
  methods: {
    declineTerms() {
      this.useTerms = false;
      this.terms = false;
      this.termsOpened = false;
    },
    agreeTerms() {
      this.useTerms = true;
      this.terms = false;
      this.termsOpened = false;
    },
    async contractHtmlUseTerms() {
      try {
        const lang = this.$i18n.locale;

        const { content, agent } = await this.userService.getContractHtml(
          this.contractTypesEnum.TERMSOFUSE,
          lang
        );

        this.contractHtmlUseTermsContent = content;
        this.contractHtmlUseTermsAgent = agent;
      } catch (err) {
        this.$toast.error(this.$t("an_error_ocurred"));
      }
    },
    openTerms() {
      this.termsOpened = true;
      this.terms = true;
    },
    async handleAgreeTerms() {
      this.loading = true;

      let payload = {
        userId: this.user.id,
        useTerms: this.useTerms,
      };

      await this.apiService
        .putRequest(`user/use-terms`, payload)
        .then((resp) => {
          const { userId, useTerms } = resp.content;

          let userLocal = JSON.parse(localStorage.getItem("user"));
          userLocal.useTerms = useTerms;

          this.$store.commit("SET_LOGGED_USER", JSON.stringify(userLocal));

          this.$router.push("/pipeline");
          this.$toast.success(this.$t("agree_terms_success"));
        })
        .catch((err) => {
          const { message } = err.body;

          if (err.status === 400) {
            this.$toast.error(message);
            return;
          }

          this.$toast.error(this.$t("an_error_ocurred"));
        });

      this.loading = false;
    },
  },
};
</script>

<style scoped>
.use-terms-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.use-terms-container .btn-agreement {
  margin-top: 20px;
}

.use-terms-container .title {
  display: block;
  font-size: 18px !important;
  font-weight: bold;
}

.use-terms-container .header {
  display: flex;
  align-items: center;
  gap: 12px;
}

.use-terms-card {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

@media (max-width: 769px) {
  .use-terms-card {
    width: 100%;
  }
}
</style>
