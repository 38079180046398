<template>
  <div>
    <v-card color="dark">
      <div :class="isMobile() ? 'py-10 px-5' : 'pa-10'">
        <v-row justify="center" align="center">
          <v-col cols="12" class="text-center" style="padding: 0">
            <span style="color: var(--white)"
              ><strong>{{ Header }}</strong></span
            >
          </v-col>
          <v-col
            cols="12"
            class="text-center"
            style="padding: 0; margin-bottom: 16px"
          >
            <span style="color: var(--white)"
              ><strong>{{ Subtitle }}</strong></span
            >
          </v-col>
        </v-row>
        <div
          :style="`height: 47vh`"
          @scroll="scrollListener"
          :data-test="`pdfModalTerms:${type}`"
          style="max-height: 100%; overflow-y: auto"
        >
          <div>
            <p v-html="Html" style="color: var(--white)"></p>
          </div>
        </div>
        <div>
          <v-row justify="center" align="center" class="pt-6">
            <v-col cols="12" class="text-center" style="padding: 0">
              <button
                :disabled="disabled"
                class="acceptModal"
                @click="$emit('agree')"
                :data-test="`pdfModalTerms:agree:${type}`"
              >
                <p class="acceptModal-title ma-2">
                  {{ $t("read_agreed_txt") }}
                </p>
              </button>
            </v-col>
            <v-col cols="12" class="text-center" style="padding: 0">
              <v-btn
                text
                height="25"
                class="declineModal mt-2"
                @click="$emit('decline')"
                >{{ $t("cancel") }}</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}

.acceptModal:disabled {
  background-color: lightgray !important;
}

.acceptModal {
  font-weight: bold;
  color: var(--white);
  background: var(--primary) !important;
  border-radius: 16px;
  box-shadow: none;
  //width: 244px;
  max-width: max-content;
  height: 54px !important;
}

.acceptModal-title {
  font-size: 15px;
}

.declineModal {
  font-weight: bold;
  color: var(--primary);
  box-shadow: none;
  width: 140px;
  height: 54px !important;
  box-sizing: border-box;
  border-radius: 15px;
  font-size: 15px;
  transition: 0.3s;
}

.declineModal:hover {
  background-color: var(--primary) !important;
  color: var(--white);
}
</style>

<script>
import ApiService from "@/services/ApiService";
import vuescroll from "vuescroll";
export default {
  name: "RegisterEmail",
  components: {
    // vuescroll,
  },
  data: () => ({
    disabled: true,
    apiService: new ApiService(),
  }),
  props: {
    BtnPlaceholder: String,
    Header: String,
    Subtitle: String,
    type: String,
    Html: String,
  },
  computed: {},
  methods: {
    scrollListener(event) {
      if (
        event.target.offsetHeight + event.target.scrollTop + 20 >=
        event.target.scrollHeight
      ) {
        this.disabled = false;
      }
    },
    isMobile() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return true;
      }
      return false;
    },
  },
};
</script>
