import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VAlert,{attrs:{"type":"info"}},[_vm._v(_vm._s(_vm.$t("agree_use_terms_alert")))]),_c(VCard,{staticClass:"pa-8 use-terms-card"},[_c('div',{staticClass:"use-terms-container"},[_c('div',{staticClass:"header"},[_c(VIcon,{attrs:{"x-large":"","color":"primary"}},[_vm._v("mdi-file-sign")]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("terms_use")))])],1),_c('div',[(_vm.terms)?_c(VDialog,{attrs:{"persistent":""},model:{value:(_vm.terms),callback:function ($$v) {_vm.terms=$$v},expression:"terms"}},[_c('TermosPDFModal',{attrs:{"type":"terms","Header":_vm.$t('terms_use'),"Html":_vm.contractHtmlUseTermsContent,"Subtitle":_vm.contractHtmlUseTermsAgent},on:{"agree":_vm.agreeTerms,"decline":_vm.declineTerms}})],1):_vm._e(),_c(VCheckbox,{staticClass:"mt-12 pa-1 mb-3 alpha-checkbox",staticStyle:{"background-color":"var(--white)"},attrs:{"hide-details":"","readonly":""},on:{"click":_vm.openTerms},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticStyle:{"color":"var(--dark)","font-size":"16px"}},[_vm._v(_vm._s(_vm.$t("read_agreed"))),_c('a',{staticClass:"ml-1",on:{"click":_vm.openTerms}},[_c('strong',{staticStyle:{"color":"var(--dark)","text-decoration":"underline"}},[_vm._v(_vm._s(_vm.$t("terms_use")))])])])]},proxy:true}]),model:{value:(_vm.useTerms),callback:function ($$v) {_vm.useTerms=$$v},expression:"useTerms"}})],1),_c(VBtn,{staticClass:"btn-agreement",attrs:{"loading":_vm.loading,"disabled":!_vm.useTerms,"color":"primary"},on:{"click":_vm.handleAgreeTerms}},[_vm._v(_vm._s(_vm.$t("agree_continue")))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }