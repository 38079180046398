import ApiService from "./ApiService";

const apiService = new ApiService();

export default class UserService {
  constructor() {}
  async getContractHtml(type, language) {
    let contract = null;

    await apiService
      .getRequest(
        `user/get-contract-html?contract=${type}&language=${language}`
      ).then((response) => {
        contract = response.content;
      })
      .catch((error) => {
        return error;
      });
      return contract;
  }
}
